.social {
  text-decoration: none;
  cursor: pointer;

  transition: 0.25s ease-in-out;
}

.social:hover {
  opacity: 0.75;
}

.social + .social {
  margin-left: 14px;
}

.socialIcon {
  width: 52px;
  height: 52px;
}


