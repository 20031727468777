@font-face {
  font-family: 'BigCityGrotesquePro';
  src: url('assets/fonts/BigCityGrotesquePro-Medium.eot?#iefix') format('embedded-opentype'),
       url('assets/fonts/BigCityGrotesquePro-Medium.woff') format('woff'),
       url('assets/fonts/BigCityGrotesquePro-Medium.ttf')  format('truetype'),
       url('assets/fonts/BigCityGrotesquePro-Medium.svg#BigCityGrotesquePro-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BigCityGrotesquePro';
  src: url('assets/fonts/BigCityGrotesquePro-Bold.eot?#iefix') format('embedded-opentype'),
       url('assets/fonts/BigCityGrotesquePro-Bold.woff') format('woff'),
       url('assets/fonts/BigCityGrotesquePro-Bold.ttf')  format('truetype'),
       url('assets/fonts/BigCityGrotesquePro-Bold.svg#BigCityGrotesquePro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}


html, body, #root {
  width: 100%;
  width: 100vw;
  height: 100%;
}

html {
  font-family: 'BigCityGrotesquePro', sans-serif;
}

p {
  margin: 0;
}
